import { graphql, Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { FaFile } from "react-icons/fa"
import { Helmet } from "react-helmet"

import BreadcrumbContainer from "../styles/BreadcrumbContainer"
import SubFolderContainer from "../styles/SubFolderContainer"
import SubFolderHeading from "../styles/SubFolderHeading"
import SubFolderHeadingTitle from "../styles/SubFolderHeadingTitle"
import SubFolderIcons from "../styles/SubFolderIcons"
import BreadcrumbLink from "../styles/BreadcrumbLink"

import getSubFolders from "../utils/getSubFolders"

const SubArticleContainer = styled(Link)`
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  word-wrap: normal;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
`

const SubArticleHeading = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`

const SubArticleHeadingTitle = styled.div`
  cursor: pointer;
  color: var(--primaryVariant);
  margin-left: 0.5rem;
  font-size: var(--doc-font-size);
`

const SubArticleIcons = styled(FaFile)`
  color: var(--primaryVariant);
  margin-right: 0.25rem;
  font-size: var(--doc-font-size);
`

export default function FolderPage({ data, pageContext }) {
  const { folder } = pageContext
  const currentDepth = (folder.match(/\//g) || []).length + 1
  const subFolders = getSubFolders([...data.markdownNodes.nodes], currentDepth)

  const subArticles = data.markdownNodes.nodes.filter(
    node => node.frontmatter.breadcrumb.length === currentDepth
  )
  const breadcrumb = folder.split("/").slice(1)

  return (
    <>
      <Helmet>
        <title>Docs: {breadcrumb[breadcrumb.length - 1]}</title>
      </Helmet>
      <BreadcrumbContainer>
        {breadcrumb.map((f, index) => {
          const slug = breadcrumb.slice(0, index + 1)

          return (
            <span key={`${f}${index}`}>
              <span> / </span>
              <BreadcrumbLink to={`/${slug.join("/")}`}>{f}</BreadcrumbLink>
            </span>
          )
        })}
      </BreadcrumbContainer>
      {subFolders.map((subFolder, index) => {
        const subFolderName = subFolder.split("/")[currentDepth]

        return (
          <SubFolderContainer key={`${subFolders}${index}`} to={subFolder}>
            <SubFolderHeading>
              <SubFolderIcons />
              <SubFolderHeadingTitle>{subFolderName}</SubFolderHeadingTitle>
            </SubFolderHeading>
          </SubFolderContainer>
        )
      })}
      {subFolders.length > 0 && <br />}
      {/* <br /> */}
      {subArticles.map((subArticle, index) => (
        <SubArticleContainer
          key={`${subArticle.frontmatter.slug}${index}`}
          to={subArticle.frontmatter.slug}
        >
          <SubArticleHeading>
            <SubArticleIcons />
            <SubArticleHeadingTitle>
              {subArticle.frontmatter.name}
            </SubArticleHeadingTitle>
          </SubArticleHeading>
        </SubArticleContainer>
      ))}
    </>
  )
}

export const query = graphql`
  query folderQuery($folder: [String]) {
    markdownNodes: allMarkdownRemark(
      filter: {
        frontmatter: { breadcrumb: { elemMatch: { slug: { in: $folder } } } }
      }
    ) {
      nodes {
        timeToRead
        frontmatter {
          slug
          title
          name
          breadcrumb {
            slug
          }
        }
        html
        id
      }
    }
  }
`
